import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { styled } from "@mui/material/styles";

// Define styled components using MUI's styled API
const Wrapper = styled(Box)(({ theme }) => ({
  width: "40%",
  margin: "0 auto",
  padding: "3rem 0 2rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  "& h5": {
    fontSize: "2rem",
    fontWeight: "bold",
    margin: "10px 0",
  },
  "& p": {
    color: "rgba(71, 77, 87, 0.8)",
    fontSize: "0.9rem",
  },
}));

const MainContent = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

// Styled link component
const StyledLink = styled("a")({
  color: "inherit",
  textDecoration: "underline",
});

const PartnerWithUs = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <MainContent>
        <Wrapper>
          <Container>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Box>
                  <Typography variant="h5">Partner with us</Typography>
                  <Typography variant="body1">
                    For partnership opportunities, please contact us at{" "}
                    <StyledLink href="mailto:partners@firestation.io">
                      partners@firestation.io
                    </StyledLink>
                    . We look forward to exploring potential collaborations with
                    you.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Wrapper>
      </MainContent>
      <Footer />
    </Box>
  );
};

export default PartnerWithUs;
