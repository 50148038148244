import React from "react";
import Slider from "react-slick";
import { Box, Grid, Typography, Link } from "@mui/material";
import { blogPosts } from "./BlogPosts";
import { Link as RouterLink } from "react-router-dom";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
};

const BlogHeader = () => {
  return (
    <Box
      sx={{
        marginBottom: {
          xs: 4, // Mobile margin bottom
          md: 8, // Desktop margin bottom
        },
        width: "100%", // Prevent horizontal overflow
        overflowX: "hidden", // Ensure no horizontal scrollbar appears
      }}
    >
      <Slider {...settings}>
        {blogPosts.map((post) => (
          <Box key={post.slug} sx={{ width: "100%" }}>
            <Grid
              container
              spacing={4}
              padding={{
                xs: 1, // Mobile padding
                md: 2, // Desktop padding
              }}
              sx={{ maxWidth: "100%", margin: "0 auto" }} // Center and constrain grid
            >
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  marginBottom: {
                    xs: 1, // Mobile margin bottom
                    md: 0, // No margin for desktop
                  },
                }}
              >
                <Link
                  component={RouterLink}
                  to={`/blog/${post.slug}`}
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <img
                    src={post.image}
                    alt={post.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "20px",
                      maxWidth: "100%", // Ensure image doesn't overflow
                    }}
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  marginBottom: {
                    xs: 2, // Mobile margin bottom
                    md: 0, // No margin for desktop
                  },
                }}
              >
                <Link
                  component={RouterLink}
                  to={`/blog/${post.slug}`}
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    sx={{
                      fontSize: {
                        xs: "1.5rem", // Mobile size
                        md: "2.5rem", // Desktop size
                      },
                    }}
                  >
                    {post.title}
                  </Typography>
                </Link>
                <Typography
                  component={RouterLink}
                  to={`/blog/${post.slug}`}
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    marginTop: 1,
                    textDecoration: "none",
                    color: "inherit",
                    fontSize: {
                      xs: "0.8rem", // Mobile size
                      md: "1rem", // Desktop size
                    },
                  }}
                >
                  {post.date}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default BlogHeader;
