export const blogPosts = [
  {
    title: "DigitalOcean's support has been amazing for Fire Station's growth",
    description:
      "We're excited to announce that our blog is coming soon! Stay tuned for updates and be the first to know when our new blog goes live.",
    image: "/images/blog/ceo/1-partnerships.png",
    date: "2024-10-03",
    slug: "introduction",
    category: "Ecosystem",
  },
  {
    title: "Fire Station is still growing, and building from the ground up",
    description:
      "We're excited to announce that our blog is coming soon! Stay tuned for updates and be the first to know when our new blog goes live.",
    image: "/images/blog/markets/1-introduction.png",
    date: "2024-10-02",
    slug: "introduction",
    category: "Markets",
  },
  {
    title: "Always double-check website URLs to ensure safety",
    description:
      "We're excited to announce that our blog is coming soon! Stay tuned for updates and be the first to know when our new blog goes live.",
    image: "/images/blog/security/1.png",
    date: "2024-09-06",
    slug: "always-double-check",
    category: "Security",
  },
  {
    title: "From the ground up Fire Station is crafting the next evolution",
    description:
      "We're excited to announce that our blog is coming soon! Stay tuned for updates and be the first to know when our new blog goes live.",
    image: "/images/blog/ecosystem/2.png",
    date: "2024-09-05",
    slug: "from-the-ground-up",
    category: "Ecosystem",
  },
  {
    title: "Join the Fire Station Announcement Channel",
    description:
      "We're excited to announce that our blog is coming soon! Stay tuned for updates and be the first to know when our new blog goes live.",
    image: "/images/blog/community/3.png",
    date: "2024-09-04",
    slug: "join-the-fire-station",
    category: "Community",
  },
  {
    title: "Flame-fueled customer focus",
    description:
      "We're excited to announce that our blog is coming soon! Stay tuned for updates and be the first to know when our new blog goes live.",
    image: "/images/blog/ecosystem/4.png",
    date: "2024-09-03",
    slug: "customer-focus",
    category: "Ecosystem",
  },
  {
    title: "Protective integrity",
    description:
      "We're excited to announce that our blog is coming soon! Stay tuned for updates and be the first to know when our new blog goes live.",
    image: "/images/blog/security/5.png",
    date: "2024-09-02",
    slug: "integrity",
    category: "Charity",
  },
  {
    title:
      "Building excellence in every layer, Fire Station is the new blueprint",
    description:
      "We're excited to announce that our blog is coming soon! Stay tuned for updates and be the first to know when our new blog goes live.",
    image: "/images/blog/education/6.png",
    date: "2024-09-01",
    slug: "building-excellence",
    category: "Research",
  },
];
