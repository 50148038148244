import React from "react";
import {
  Box,
  Container,
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { styled } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material"; // Import SelectChangeEvent from MUI

// Define styled components using MUI's styled API
const Wrapper = styled(Box)(({ theme }) => ({
  width: "40%",
  margin: "0 auto",
  padding: "3rem 0 2rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const MainContent = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginTop: "1rem", // Adjust space between verification message and main content
}));

const VerifyWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#920000",
  padding: "1rem",
  marginBottom: "1rem", // Space between verification message and content below
  "@media (max-width: 600px)": {
    padding: "0.5rem", // Reduce padding on small screens
  },
}));

const VerifyInfo = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  color: "#fff", // Text color for better visibility on background
  "@media (max-width: 600px)": {
    flexDirection: "row", // Stack elements vertically on small screens
    alignItems: "flex-start", // Align items to the start on small screens
    textAlign: "left", // Ensure text is aligned to the left on small screens
  },
  "& svg": {
    width: "24px",
    height: "24px",
    marginRight: "1rem",
    color: "#fff", // Icon color to match text color
    "@media (max-width: 600px)": {
      width: "16px", // Reduce icon size on small screens
      height: "16px", // Reduce icon size on small screens
      marginRight: "0.5rem", // Adjust margin on small screens
    },
  },
  "& div:nth-of-type(1)": {
    marginRight: "0.5rem", // Add space after the first div
  },
  "& .verification-url": {
    color: "#fff", // Ensure the URL text is white
    "@media (max-width: 600px)": {
      fontSize: "0.7rem", // Adjust font size on small screens
    },
  },
  "& .green-text": {
    color: "#28a745", // Green color for https://
  },
}));

const CustomButton = styled(Button)({
  backgroundColor: "#920000",
  color: "#ffffff",
  padding: "0 1rem", // Adjust padding to fit the height
  paddingLeft: "2rem",
  paddingRight: "2rem",
  borderRadius: "4px",
  height: "56px", // Match height with other fields
  textTransform: "none", // To prevent uppercase transformation
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#7d0000",
  },
});

const StyledParagraph = styled("p")(({ theme }) => ({
  fontSize: "1rem",
  textAlign: "center",
  color: theme.palette.text.secondary,
  width: "100%",
  margin: "0",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
}));

const FireStationVerify = () => {
  const [category, setCategory] = React.useState<string>("Website");

  const handleChange = (event: SelectChangeEvent<string>) => {
    setCategory(event.target.value as string);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <VerifyWrapper>
        <VerifyInfo>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 8v2H5v11h14V10h-2V8A5 5 0 007 8zm7.5 2V8a2.5 2.5 0 00-5 0v2h5zm-1 8v-5h-3v5h3z"
              fill="#28a745"
            ></path>
          </svg>
          <div className="verification-url">URL verification:</div>
          <div className="verification-url">
            <span className="green-text">https://</span>www.firestation.io
          </div>
        </VerifyInfo>
      </VerifyWrapper>
      <MainContent>
        <Wrapper>
          <Container>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    src="/images/firestation-wordmark-red.png" // Path to your logo image
                    alt="Fire Station Logo"
                    sx={{
                      width: {
                        xs: "120px", // Mobile size
                        sm: "150px", // Tablet size
                        md: "200px", // Desktop size
                      },
                      height: "auto",
                      marginBottom: "1rem",
                    }}
                  />
                  <StyledParagraph>
                    Stay safe from scammers. Enter a URL, email address, phone
                    number, Telegram, or social media accounts to check if the
                    source is verified and officially from Fire Station.
                  </StyledParagraph>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      sx={{ minWidth: 140, mr: 2, height: "56px" }}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={category}
                        onChange={handleChange}
                        label="Category"
                      >
                        <MenuItem value="Website">Website</MenuItem>
                        <MenuItem value="Email">Email</MenuItem>
                        <MenuItem value="Facebook">Facebook</MenuItem>
                        <MenuItem value="Instagram">Instagram</MenuItem>
                        <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                        <MenuItem value="Phone Number">Phone Number</MenuItem>
                        <MenuItem value="X(Twitter)">X(Twitter)</MenuItem>
                        <MenuItem value="Telegram">Telegram</MenuItem>
                        <MenuItem value="TikTok">TikTok</MenuItem>
                        <MenuItem value="YouTube">YouTube</MenuItem>
                        <MenuItem value="Discord">Discord</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      placeholder="Enter query"
                      variant="outlined"
                      sx={{ flex: 1, mr: 2, height: "56px", width: "600px" }} // Make the text field wider
                    />
                    <CustomButton>Search</CustomButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Wrapper>
      </MainContent>
      <Footer />
    </Box>
  );
};

export default FireStationVerify;
