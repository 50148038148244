import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import {
  Avatar,
  Box,
  Button,
  Container,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { blogPosts } from "./BlogPosts";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
} from "react-share";
import InstagramIcon from "@mui/icons-material/Instagram";

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const post = blogPosts.find((p) => p.slug === slug);

  if (!post) {
    return <Typography>Blog post not found</Typography>;
  }

  const shareUrl = `${window.location.origin}/blog/${post.slug}`;

  return (
    <>
      <Navbar />
      <Box sx={{ padding: "4rem 0", position: "relative" }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column", // Stack content vertically on mobile
              sm: "row", // Horizontal layout on larger screens
            },
            gap: 4,
          }}
        >
          {/* Blog Content Section */}
          <Box sx={{ flex: 2 }}>
            <Box
              sx={{
                display: {
                  xs: "none", // Hide on mobile
                  sm: "flex", // Show on larger screens
                },
                alignItems: "center",
                marginBottom: 3,
                cursor: "pointer",
                "&:hover": {
                  color: "#920000",
                },
              }}
              onClick={() => navigate(-1)}
            >
              <IconButton sx={{ marginRight: 1, color: "inherit" }}>
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "inherit",
                }}
              >
                Fire Station Blog
              </Typography>
            </Box>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "1.75rem", // Smaller font size for mobile
                  sm: "2.5rem", // Default font size for tablets and above
                },
                fontWeight: "bold",
              }}
            >
              {post.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 5 }}
            >
              {new Date(post.date).toLocaleDateString()}
            </Typography>
            <img
              src={post.image}
              alt={post.title}
              style={{ width: "100%", height: "auto", borderRadius: "20px" }}
            />
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              {post.description}
            </Typography>
          </Box>

          {/* Social Share Tab */}
          <Box
            sx={{
              display: {
                xs: "none", // Hide on mobile view
                sm: "block", // Show on larger screens
              },
              width: 300,
              position: "sticky",
              top: 0,
              padding: 2,
              height: "fit-content",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Share Post:
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <FacebookShareButton url={shareUrl}>
                <Avatar
                  sx={{ width: 24, height: 24, filter: "grayscale(100%)" }} // Grayscale for X icon
                  src="/images/social/facebook.svg"
                  alt="X Icon"
                />
              </FacebookShareButton>

              {/* Custom X (Twitter) icon */}
              <a
                href={`https://x.com/share?url=${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Avatar
                  sx={{ width: 24, height: 24, filter: "grayscale(100%)" }} // Grayscale for X icon
                  src="/images/social/x.svg"
                  alt="X Icon"
                />
              </a>

              <LinkedinShareButton url={shareUrl}>
                <Avatar
                  sx={{ width: 24, height: 24, filter: "grayscale(100%)" }} // Grayscale for X icon
                  src="/images/social/linkedin.svg"
                  alt="X Icon"
                />
              </LinkedinShareButton>

              <RedditShareButton url={shareUrl}>
                <Avatar
                  sx={{ width: 24, height: 24, filter: "grayscale(100%)" }} // Grayscale for X icon
                  src="/images/social/reddit.svg"
                  alt="X Icon"
                />
              </RedditShareButton>

              <TelegramShareButton url={shareUrl}>
                <Avatar
                  sx={{ width: 24, height: 24, filter: "grayscale(100%)" }} // Grayscale for X icon
                  src="/images/social/telegram.svg"
                  alt="X Icon"
                />
              </TelegramShareButton>

              {/* Instagram icon rounded */}
              <a
                href={`https://www.instagram.com/?url=${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "flex", alignItems: "center" }}
              >
                  <Avatar
                    sx={{ width: 24, height: 24, filter: "grayscale(100%)" }} // Grayscale for X icon
                    src="/images/social/instagram.svg"
                    alt="X Icon"
                  />
              </a>

              {/* Custom Threads icon */}
              <a
                href={`https://www.threads.net/share?url=${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Avatar
                  sx={{ width: 24, height: 24, filter: "grayscale(100%)" }}
                  src="/images/social/thread.svg"
                  alt="Threads Icon"
                />
              </a>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start", // Align image and text to the left
                backgroundColor: "#f5f5f5", // Gray background color
                padding: 2, // Add padding for better spacing
                borderRadius: "10px", // Optional: for rounded corners on the background
                marginTop: 3,
              }}
            >
              <img
                src="/images/3d-firechain.png" // Add the path to the new image
                alt="Why Choose Us"
                style={{ width: "50%", height: "auto", borderRadius: "10px" }} // Adjust styling as needed
              />
              <Typography
                variant="body1"
                sx={{
                  marginTop: 2,
                  fontWeight: "bold",
                  fontSize: "1rem", // Adjust the font size as needed
                }}
              >
                Users chose us. Find out why today.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 3,
                }}
              >
                <Button
                  href="/register"
                  variant="contained"
                  sx={{
                    width: "100%",
                    maxWidth: "150px",
                    backgroundColor: "#920000",
                    color: "white",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "#c81010",
                    },
                    textAlign: "center",
                  }}
                >
                  Register Now
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "none",
              },
              flexDirection: "row",
              justifyContent: "center",
              gap: 2,
              marginTop: 4,
            }}
          >
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon
                size={24}
                round
                style={{ filter: "grayscale(100%)" }}
              />
            </FacebookShareButton>

            {/* Custom X (Twitter) icon */}
            <a
              href={`https://x.com/share?url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                sx={{ width: 24, height: 24, filter: "grayscale(100%)" }}
                src="/images/social/x.svg"
                alt="X Icon"
              />
            </a>

            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon
                size={24}
                round
                style={{ filter: "grayscale(100%)" }}
              />
            </LinkedinShareButton>

            <RedditShareButton url={shareUrl}>
              <RedditIcon
                size={24}
                round
                style={{ filter: "grayscale(100%)" }}
              />
            </RedditShareButton>

            <TelegramShareButton url={shareUrl}>
              <TelegramIcon
                size={24}
                round
                style={{ filter: "grayscale(100%)" }}
              />
            </TelegramShareButton>

            {/* Instagram icon rounded */}
            <a
              href={`https://www.instagram.com/?url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: "#E1306C",
                  filter: "grayscale(100%)",
                }}
              >
                <InstagramIcon
                  fontSize="small"
                  style={{ color: "white", filter: "grayscale(100%)" }}
                />
              </Avatar>
            </a>

            {/* Custom Threads icon */}
            <a
              href={`https://www.threads.net/share?url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                sx={{ width: 24, height: 24, filter: "grayscale(100%)" }} // Grayscale for Threads icon
                src="/images/social/thread.svg"
                alt="Threads Icon"
              />
            </a>
          </Box>
        </Container>

        {/* Register Now Button */}
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "none",
            },
            justifyContent: "center",
            marginTop: 4,
          }}
        >
          <Button
            href="/register"
            variant="contained"
            sx={{
              backgroundColor: "#920000",
              color: "white",
              "&:hover": {
                backgroundColor: "#c81010",
              },
              textAlign: "center",
              borderRadius: "10px",
            }}
          >
            Register Now
          </Button>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default BlogPost;
